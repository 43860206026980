import gladwell from '~/lang/en.js';

export default {
    ...gladwell,
    footer: {
        ...gladwell.footer,
        copyright: '© {year} Highberg Academy All rights reserved. All mentioned prices are exclusive of VAT and taxes. Secure payment by',
        'gladwell-academy': 'Highberg Academy',
    },
    edition: {
        ...gladwell.edition,
        usp: {
            ...gladwell.edition.usp,
            title: 'Why Highberg Academy?',
        }
    },
    'stat-list': {
        'recommended-rate': 'Recommended rate',
        'organizations-participating': 'Organizations participating',
        'participants-enrolled': 'Participants enrolled',
    },
};
