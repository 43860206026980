
import favicons from '~/mixins/favicons';
import GwHeader from '~/patterns/organisms/header/header.vue';
import GwFooter from '~/patterns/organisms/footer/footer.vue';

export default {
    components: {
        GwHeader,
        GwFooter
    },
    mixins: [favicons],
    head() {
        return {
            meta: [
                ...this.faviconMeta
            ],
            link: [
                ...this.faviconLink
            ]
        };
    },
};
