
import { DnCookiePreferences } from '@digitalnatives/cookie-consent';
import Cookies from 'universal-cookie';
import GwModal from '~/patterns/atoms/modal/modal.vue';
import GwCheckbox from '~/patterns/atoms/checkbox/checkbox.vue';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnCookiePreferences,
        GwCheckbox,
        GwButton,
        GwModal,
        GwIcon
    },

    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            preferences: {}
        };
    },

    computed: {
        isSaving() {
            return this.$cookieConsent.isSaving();
        },
        checked() {
            return this.$cookieConsent.cookieVm.checkedCategories;
        }
    },

    beforeMount() {
        this.preferences = [...this.$cookieConsent.cookieVm.checkedCategories];
    },

    mounted() {
        // Workaround for HS, opt out of tracking by default
        if (!this.$cookieConsent.isCookiebarDismissed()) {
            this.setHubspotOptOut('yes');
        }
    },

    methods: {
        save() {
            // If marketing cookies are accepted, remove HS opt out
            if (this.checked.includes('marketing')) {
                this.setHubspotOptOut('no');
            }

            this.$cookieConsent.save();

            this.$emit('closeModal');
        },

        closeModal() {
            const confirmed = confirm(this.$t('cookiebar.preferences.confirm'));

            if (confirmed) {
                this.save();
            }
        },

        setHubspotOptOut(value) {
            const expires = new Date();
            const exdays = 365;

            expires.setTime(expires.getTime() + (exdays * 24 * 60 * 60 * 1000));

            let cookies;
            if (this.$nuxt.req) {
                cookies = new Cookies(this.$nuxt.req.headers.cookie);
            } else {
                cookies = new Cookies();
            }

            cookies.set('__hs_do_not_track', value, { expires, path: '/' });
        }
    },

};
