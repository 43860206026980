
import ImageSingle from '~/patterns/atoms/image-single/image-single';

export default {
    components: {
        ImageSingle
    },
    props: {
        image: {
            type: Object,
            default: () => { return {}; }
        },
        staticUrl: {
            type: String,
            default: ''
        },
        alt: {
            type: String,
            default: null
        }
    },
    computed: {
        hasImage() {
            return Object.keys(this.image).length > 0;
        }
    },
    methods: {
        getInitials(text) {
            return text.match(/\b(\w)/g).join('');
        }
    }
};
