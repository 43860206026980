
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwCtaCard from '~/patterns/atoms/card/presets/cta-card.vue';
import GwNav from '~/patterns/molecules/nav/nav.vue';
import ImageSingle from '~/patterns/atoms/image-single/image-single.vue';
import GwCurrencySwitch from '~/patterns/molecules/currency-switch/currency-switch.vue';
import GwDomainSwitch from '~/patterns/molecules/domain-switch/domain-switch.vue';

import getFooterMenu from '~/graphql/queries/getFooterMenu.graphql';

export default {
    components: {
        GwPageContainer,
        GwCtaCard,
        ImageSingle,
        GwNav,
        GwCurrencySwitch,
        GwDomainSwitch
    },

    props: {
        contactOverride: {
            type: Object,
            default: undefined,
            required: false
        },
        showContact: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            footerMainMenu: undefined,
            footerSecondaryMenu: undefined,
            footerTertiaryMenu: undefined,
            footerSubMenu: undefined,
            salesContact: undefined,
            filename: this.$config.site.group === 'highberg' ? 'logos-highberg' : 'logos-gladwell',
            image: {
                url: '/images/wessel-van-dongen.jpeg',
                url_tiny: '/images/wessel-van-dongen.jpeg',
                width: 800,
                height: 800
            }
        };
    },

    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(getFooterMenu, { site });

        if (data && data.globalSets.length > 0) {
            // Map globalSets to data but only not if populated already
            data.globalSets.forEach((set) => {
                if (!this[set.handle] && set.entryLinks) {
                    this[set.handle] = set.entryLinks;
                } else if (!this[set.handle] && set.contact) {
                    this[set.handle] = set;
                }
            });
        }
    },

    watch: {
        contactOverride: {
            handler(data) {
                if (data?.contact) {
                    this.salesContact = data;
                }
            },
            immediate: true
        }
    },
};
