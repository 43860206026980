import { kebabCase } from 'lodash';

/**
 * Step 1: Retrieve a list of all available svg files in
 * the ~assets/img/icons directory.
 */
const iconsContext = require.context(
        // Path to your icon directory
        '~/assets/img/icons',
        // Should it also include sub directories?
        true,
        // Make sure only svg files are loaded
        /\.svg$/,
        // Only load the file names, don't import any code yet
        'lazy'
    ),
    components = {};

/**
 * Step 2: Create an array of icon names.
 * - Exporting it can help you create an overview of all
 * available components elsewhere.
 */
export const icons = iconsContext.keys().map((filename) => {
    // Strip the extension and "./" prefix
    const filepath = filename.replace(/^\.\//, '').replace(/\.svg$/, '');
    // Component names are all prefixed with "icon",
    // to prevent collisions with reserved HTML elements or other vue components
    return {
        filepath,
        componentName: 'icon-' + kebabCase(filepath)
    };
});

/**
 * Step 3: Create an object containing all of your icons as components
 * - SVG files are loaded using @nuxtjs/svg.
 * - The ?inline parameter is used to turn the file's contents
 * into a Vue component.
 * - Async imports are used to make sure only icons that are
 * actually used will be loaded.
 */
icons.forEach((iconPath) => {
    components[iconPath.componentName] = () => import('~/assets/img/icons/' + iconPath.filepath + '.svg?inline');
});

/**
 * Step 4: Export the components object for the
 * Vue component to consume.
 */
export default components;
