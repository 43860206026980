export default {
    submit: 'Verstuur',
    home: {
        courses: 'trainingen',
        course: 'training',
        'courses-link': 'Trainingen',
        'all-courses-link': 'Alle Trainingen',
        'all-trainers-link': 'Alle Trainers'
    },
    course: {
        'enroll-today': 'Inschrijven',
        'request-quote': 'Offerte aanvragen',
        'download-brochure': 'Download brochure',
        'participant-score': 'Deelnemersbeoordeling',
        'success-rate': 'Slagingspercentage',
        'cta-title': 'Weet u niet zeker of deze training bij u past?',
        'cta-body': 'Onze training adviseur {naam} helpt je graag verder.'
    },
    checkout: {
        title: 'Maak je inschrijving compleet',
        'remove-participant': 'Verwijder deelnemer',
        'add-participant': 'Voeg deelnemer toe ',
        'validation-notice': 'Vul alle velden in voordat je doorgaat naar de volgende stap',
        'payment-method-notice': 'Selecteer een betaalmethode',
        required: 'Dit veld is verplicht',
        error: {
            title: 'An error has occurred.',
            body: 'We kunnen deze bestelling momenteel niet verwerken, ververs de pagina en probeer het opnieuw. U kunt ook contact met ons opnemen voor ondersteuning en we zullen u zo snel mogelijk helpen.'
        },
        labels: {
            'first-name': 'Voornaam',
            'last-name': 'Achternaam',
            email: 'Email',
            phone: 'Telefoonnummer',
            'customer-type': 'Type Gladwell-account ',
            'type-corporate': 'Zakelijk',
            'type-personal': 'Particulier',
            country: 'Land',
            state: 'State',
            zip: 'Postcode',
            city: 'Stad',
            address: 'Adres ',
            company: 'Bedrijf',
            'job-title': 'Functie',
            vat: 'BTW-Nummer',
            reference: 'Boekingsnummer'
        },
        placeholders: {
            'first-name': 'John',
            'last-name': 'Janssen',
            email: 'John@janssen.com',
            phone: '+31 06 12345678',
            country: 'Land',
            zip: '1234 AB',
            city: 'Agile Stad',
            address: 'Scrumstraat 21',
            company: 'Company B.V.',
            'job-title': 'Scrum Master',
            vat: '123456789B01',
            'select-country': 'Kies land',
            'select-state': 'Kies provincie',
            reference: '123456789',
            coupon: 'Coupon code'
        },
        client: {
            title: 'Deelnemer',
            'button-next': 'Volgende stap: Betaalgegevens '
        },
        'special-offer': {
            title: 'Speciale aanbieding',
            'button-next': 'Volgende: Speciale aanbieding',
        },
        billing: {
            title: 'Betaalgegevens',
            'button-next': 'Volgende: Betalen',
            'reuse-information': 'Gebruik contactinformatie van eerste deelnemer'
        },
        payment: {
            title: 'Betalen',
            'button-next': 'Bevestig inschrijving',
            'adyen-submit': 'Bevestig betaling',
            failed: 'Payment failed',
            'failed-message': 'try again or with a different method',
        },
        'order-summary': {
            'more-info': 'Meer informatie',
            'less-info': 'Minder informatie',
            trainers: 'Trainers',
            participant: 'deelnemer',
            participants: 'deelnemers',
            vat: 'BTW',
            'calculation-notice': 'Berekend in volgende stap',
            total: 'Totaal',
            'add-coupon': 'Voeg kortingscode toe',
            'cancel-coupon': 'Annuleren',
            'apply-coupon': 'Kortingscode toepassen',
            'invalid-coupon': 'De kortingscode is ongeldig'
        },
        statuses: {
            success: {
                title: 'Geweldig, je bestelling is gelukt!',
                description: 'Bedankt voor je aanmelding. Binnenkort ontvang je een bevestiging en factuur.',
            },
            error: {
                title: 'Betaling mislukt',
                description: 'Probeer u opnieuw in te schrijven of neem contact met ons op voor ondersteuning.',
            },
            pending: {
                title: 'Wachten op betaling',
                description: 'Wij hebben nog geen bevestiging van uw betaling ontvangen. Als we dat doen, ontvang je binnen korte tijd een bevestigings- en factuur-e-mail. Als je de betaling zelf hebt geannuleerd of als er een fout is opgetreden tijdens het proberen, probeer je dan opnieuw in te schrijven of neem contact met ons op voor ondersteuning.',
            },
            loading: {
                title: 'Wachten op de betaling',
                description: 'Even geduld alstublieft',
            },
        }
    },
    editions: {
        title: 'Edities',
    },
    'stat-list': {
        'succesful-alumni': 'Succesvolle alumni',
        'market-leader': 'SAFe training marktleider',
        'certified-trainers': 'Gecertificeerde trainers',
    },
    schedule: {
        title: 'Trainingskalender',
        results: 'resultaten',
        result: 'resultaat',
        'loading-results': 'Resultaten worden geladen...',
        'no-results': 'Geen resulaten gevonden,',
        'clear-filters': 'wis filters',
        'to-show-all-options': 'om alle resultaten te tonen',
        programs: 'Programma\'s',
        filter: 'Filter',
        courses: 'Trainingen',
        'start-date': 'Startdatum',
        location: 'Locatie',
        language: 'Taal',
        attendance: 'Aanwezigheid',
        'show-next': 'Toon volgende {count}',
        'of-total': 'van {count} resultaten.',
        'ex-vat': 'excl. BTW',
        'vat-text': '',
        enroll: 'Inschrijven'
    },
    trainer: {
        label: 'Gecertificeerde trainer'
    },
    nav: {
        question: 'Vragen?',
        currency: 'Valuta',
        'schedule-meeting': 'Neem contact op',
        language: 'Taal veranderen',
        nl: 'Nederlands',
        en: 'Engels',
        de: 'Duits',
        fr: 'Frans'
    },
    footer: {
        copyright: '© {year} Gladwell Academy - Alle rechten voorbehouden. Alle genoemde genoemde bedragen zijn exclusief BTW. Betalingen beveiligd door',
        courses: 'Trainingen',
        'gladwell-academy': 'Gladwell Academy',
        'knowledge-hub': 'Knowledge Hub',
    },
    cookiebar: {
        title: 'Cookies',
        message: 'We gebruiken cookies om uw ervaring tijdens het browsen op onze website te verbeteren. Cookies worden gebruikt om ads te personaliseren. Voor meer informatie zie ons {privacy}',
        link: 'Privacybeleid ',
        saving: 'Voorkeuren worden opgeslagen...',
        'configure-preferences': 'Voorkeuren',
        accept: 'Accepteer Cookies',
        preferences: {
            title: 'Cookievoorkeuren',
            description: 'Hieronder kunt u uw cookievoorkeuren configureren. Technisch noodzakelijke cookies zijn essentieel om de website goed te laten functioneren. We gebruiken andere cookies of gerelateerde technologieën alleen als we uw voorafgaande toestemming hebben. U heeft op elk moment de mogelijkheid om uw instellingen te controleren en te wijzigen. Gedetailleerde informatie vindt u in onze {link}.',
            link: 'Privacybeleid',
            subtitle: 'Configureer uw voorkeuren',
            confirm: 'Door dit venster te sluiten, accepteert u ook de huidige privacyinstellingen. Klik op "OK" om te accepteren of annuleer om wijzigingen aan te brengen.',
            save: 'Voorkeuren opslaan'
        },
        functional: {
            title: 'Functionele cookies',
            description: 'die nodig zijn voor het goed functioneren van de website.'
        },
        analytical: {
            title: 'Analytische cookies',
            description: 'die we gebruiken om inzicht te krijgen in hoe bezoekers de website gebruiken'
        },
        marketing: {
            title: 'Tracking cookies',
            description: 'waarmee we inzicht krijgen in het gedrag van een bezoeker over één of meerdere domeinen.'
        }
    },
    'not-found': {
        title: 'Er ontbreekt iets...',
        message: 'We kunnen de pagina die je zoekt niet vinden.'
    },
    error: {
        title: 'Er ging iets mis...',
        message: 'Het lijkt erop dat er iets mis is gegaan.'
    },
    'not-available-in-language': {
        title: 'Niet beschikbaar in deze taal',
        description: 'Deze pagina is niet beschikbaar in de door u gekozen taal.',
        instruction: 'Ga terug naar de',
        home: 'homepagina',
        or: 'of',
        'go-back': 'ga terug'
    },
    thankyou: {
        title: 'Bedankt!',
        message: ''
    },
    form: {
        'select-option': 'Selecteer optie',
    },
    event: {
        'get-tickets': 'Koop je tickets',
        'get-tickets-now': 'Koop nu je tickets',
        'read-more': 'Meer lezen'
    },
    edition: {
        trainers: {
            title: 'Trainers'
        },
        'training-dates': {
            title: 'Trainingsdata'
        },
        'more-info': 'Meer over deze training',
        'show-more': 'Show more',
        'show-less': 'Show less',
        usp: {
            title: 'Waarom Gladwell?',
            first: 'Meer dan 50.000 professionals getraind',
            second: 'Transformaties in meer dan 7 talen',
            third: 'Gold Partner van Scaled Agile'
        },
        'download-brochure': 'Download Brochure',
        brochure: 'Brochure',
        'enroll-now': 'Inschrijven',
        conjunction: 'en'
    },
    knowledge: {
        'written-by': 'Geschreven door',
        search: 'Zoeken',
        categories: 'Categorieën',
        all: 'Alles',
    }
};
