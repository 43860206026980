
// You can also choose to add the lazy-image component instead of the src-set
export default {
    props: {
        caption: {
            type: String,
            required: false,
            default: ''
        },
        imageUrl: {
            type: String,
            required: true,
            default: ''
        },
        webpUrl: {
            type: String,
            required: false,
            default: ''
        },
        srcset: {
            type: String,
            required: false,
            default: ''
        },
        srcsetWebp: {
            type: String,
            required: false,
            default: ''
        },
        sizes: {
            type: String,
            required: false,
            default: '100vw'
        },
        loading: {
            type: String,
            default: 'lazy'
        },
        decoding: {
            type: String,
            default: 'auto'
        },
        width: {
            type: Number,
            default: null
        },
        height: {
            type: Number,
            default: null
        },
    }
};
