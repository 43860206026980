
import GwBlock from '~/patterns/atoms/block/block.vue';
import GwAvatar from '~/patterns/atoms/avatar/avatar.vue';

export default {
    components: {
        GwBlock,
        GwAvatar
    },
    props: {
        contact: {
            type: Object,
            default: () => { return {}; }
        }
    },
    data() {
        return {
            image: {
                url: '/images/wessel-van-dongen.jpeg',
                url_tiny: '/images/wessel-van-dongen.jpeg',
                width: 800,
                height: 800
            }
        };
    }
};
