import gladwell from '~/lang/de.js';

export default {
    ...gladwell,
    footer: {
        ...gladwell.footer,
        copyright: '© {year} Highberg Academy  - Alle Rechte vorbehalten. Alle genannten Preise verstehen sich exklusive Mehrwertsteuer und Steuern. Sichere Zahlung per',
        'gladwell-academy': 'Highberg Academy',
    },
    edition: {
        ...gladwell.edition,
        usp: {
            ...gladwell.edition.usp,
            title: 'Warum die Highberg Academy?',
        }
    },
    'stat-list': {
        'recommended-rate': 'Empfohlene Bewertungen',
        'organizations-participating': 'Teilnehmende Organisationen',
        'participants-enrolled': 'Angemeldete Teilnehmer',
    },
};
