
import GwCard from '~/patterns/atoms/card/card.vue';

export default {
    components: {
        GwCard
    },
    props: {
        program: {
            type: Object,
            default: () => { return {}; }
        },
        imageAlignment: {
            type: String,
            default: 'left'
        }
    }
};
