export const state = () => ({
    pageType: undefined,
    navigationCTA: undefined,
    contact: undefined
});

export const mutations = {
    setPageType(scopedState, pageType) {
        scopedState.pageType = pageType;
    },
    setNavigationCTA(scopedState, navigationCTA) {
        scopedState.navigationCTA = navigationCTA;
    },
    setContact(scopedState, contact) {
        scopedState.contact = contact;
    }
};

export const actions = {
    setPageType({ commit }, pageType) {
        commit('setPageType', pageType);
    },
    setContact({ commit }, contact) {
        commit('setContact', contact);
    }
};

export const getters = {
    getPageType: (data) => {
        return data.pageType;
    },

    getContact: (data) => {
        return data.contact;
    }
};
