
import GwDefaultTemplate from '~/patterns/templates/default';
import GwPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import GwBackdrop from '~/patterns/atoms/backdrop/backdrop';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';

export default {
    components: {
        GwBackdrop,
        GwDefaultTemplate,
        GwPageTitle,
        GwPageContainer
    },
    props: {
        notFound: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        errorMessage() {
            return (this.error && this.error.statusCode === 404) || this.notFound ? this.$t('not-found.message') : this.$t('error.title');
        }
    }
};
