'use strict';

export default {
    computed: {
        faviconLink() {
            let filename = '/favicon-gladwell';

            if (this.$config.site.group === 'highberg') {
                filename = '/favicon-highberg';
            }
            return [
                { rel: 'shortcut icon', type: 'image/x-icon', href: `${filename}/favicon.ico` },
                { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${filename}/favicon-16x16.png` },
                { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${filename}/favicon-32x32.png` },
                { rel: 'apple-touch-icon', sizes: '180x180', href: `${filename}/apple-touch-icon.png` },
                { rel: 'mask-icon', href: `${filename}/safari-pinned-tab.svg` },
                { rel: 'manifest', href: `${filename}/site.webmanifest', crossorigin: 'use-credentials` },
            ];
        },
        faviconMeta() {
            return [
                { name: 'msapplication-TileColor', content: this.faviconThemeColor },
                { name: 'msapplication-config', content: '/browserconfig.xml' },
                { name: 'theme-color', content: this.faviconThemeColor }
            ];
        },
        faviconThemeColor() {
            return '#001531';
        }
    }
};
