export default function(event) {
    const error = event.originalError;
    if (!error) {
        return;
    }

    // Add additional diagnostic information
    if (Object.prototype.hasOwnProperty.call(error, 'gqlErrors')) {
        event.addMetadata('gql', {
            errors: error.gqlErrors
        });
    }

    if (Object.prototype.hasOwnProperty.call(error, 'gqlQuery')) {
        event.addMetadata('gql', {
            query: error.gqlQuery
        });
    }
}
