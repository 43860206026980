
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwNavigation from '~/patterns/organisms/navigation/navigation.vue';

export default {
    components: {
        GwPageContainer,
        GwNavigation
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        elements: {
            type: Array,
            default: () => { return ['contact', 'tertiary-menu', 'menu-button']; }
        }
    },
    data() {
        return {
            filename: this.$config.site.group === 'highberg' ? 'logos-highberg' : 'logos-gladwell',
            siteGroup: this.$config.site.group === 'highberg' ? 'Highberg' : 'Gladwell Academy'
        };
    }
};
