export default {
    submit: 'Absenden',
    home: {
        courses: 'Trainings',
        course: 'Training',
        'courses-link': 'Training',
        'all-courses-link': 'Alle Trainings',
        'all-trainers-link': 'Alle Trainer'
    },
    course: {
        'enroll-today': 'Jetzt anmelden',
        'download-brochure': 'Broschüre herunterladen',
        'request-quote': 'Angebot anfragen',
        'participant-score': 'Teilnehmer Wertung',
        'success-rate': 'Erfolgsrate',
        'cta-title': 'Sie möchten mehr erfahren?',
        'cta-body': 'Kontaktieren Sie gerne unseren Business Development Manager {name} oder vereinbaren Sie direkt einen Termin.'
    },
    checkout: {
        title: 'Anmeldung abschliessen',
        'remove-participant': 'Teilnehmer entfernen',
        'add-participant': 'Teilnehmer hinzufügen',
        'validation-notice': 'Bitte alle Felder ausfüllen für den folgenden Schritt',
        'payment-method-notice': 'Bitte wählen Sie eine Zahlungsmethode bevor Sie Ihre Anmeldung fortsetzen',
        required: 'Pflichtfeld ',
        error: {
            title: 'An error has occurred.',
            body: 'Wir können diese Bestellung im Moment nicht verarbeiten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Alternativ können Sie sich bei uns melden, um Unterstützung zu erhalten, und wir werden Ihnen so schnell wie möglich helfen.'
        },
        labels: {
            'first-name': 'Vorname',
            'last-name': 'Nachname',
            email: 'E-Mail',
            phone: 'Telefonnummer',
            'customer-type': 'Art der Buchung',
            'type-corporate': 'Branche',
            'type-personal': 'individuell',
            country: 'Land',
            state: 'State',
            zip: 'Postleitzahl',
            city: 'Stadt',
            address: 'Strasse',
            company: 'Firmenname',
            'job-title': 'Berufsbezeichnung',
            vat: 'Steuernummer',
            reference: 'Referenznummer'
        },
        placeholders: {
            'first-name': 'Max',
            'last-name': 'Mustermann',
            email: 'max@mustermann.de',
            phone: '0049 173321456',
            country: 'Land',
            zip: '12345',
            city: 'Musterstadt',
            address: 'Scrumstraße 21',
            company: 'Muster GmbH',
            'job-title': 'Scrum Master',
            vat: '123456789B01',
            'select-country': 'Land wählen',
            'select-state': 'Select state',
            reference: '123456789',
            coupon: 'Coupon Code'
        },
        client: {
            title: 'Teilnehmer',
            'button-next': 'Folgender Schritt: Rechnungsinformationen'
        },
        'special-offer': {
            title: 'Sonderangebot',
            'button-next': 'Folgender Schritt: Sonderangebot',
        },
        billing: {
            title: 'Rechnungsinformationen',
            'button-next': 'Folgender Schritt: Zahlung',
            'reuse-information': 'Kontaktdaten des ersten Teilnehmers verwenden'
        },
        payment: {
            title: 'Zahlung',
            'button-next': 'Buchung bestätigen',
            'adyen-submit': 'Zahlung bestätigen',
            failed: 'Zahlung fehlgeschlagen',
            'failed-message': 'versuchen Sie es bitte erneut oder wählen Sie eine andere Zahlungsmethode',
        },
        'order-summary': {
            'more-info': 'Mehr Informationen',
            'less-info': 'Weniger anzeigen ',
            trainers: 'Trainer',
            participant: 'Teilnehmer',
            participants: 'Teilnehmer',
            vat: 'Umsatzsteuer',
            'calculation-notice': 'Ust.-Berechnung folgt',
            total: 'Gesamt',
            'add-coupon': 'Coupon hinzufügen',
            'cancel-coupon': 'Coupon entfernen',
            'apply-coupon': 'Coupon anwenden',
            'invalid-coupon': 'Coupon Code ungültig'
        },
        statuses: {
            success: {
                title: 'Großartig, Ihre Bestellung war erfolgreich',
                description: 'Vielen Dank für Ihre Bestellung. Sie haben sich Zeit zum Lernen geschenkt. Sie erhalten innerhalb kürzester Zeit eine Bestätigungs- und Rechnungs-E-Mail.',
            },
            error: {
                title: 'Zahlung fehlgeschlagen',
                description: 'Bitte versuchen Sie sich erneut anzumelden, oder wenden Sie sich an uns, um Unterstützung zu erhalten.',
            },
            pending: {
                title: 'Warten auf Zahlungsabwicklung',
                description: 'Wir haben noch keine Bestätigung Ihrer Zahlung erhalten. Wenn wir das tun, erhalten Sie innerhalb kurzer Zeit eine Bestätigungs- und Rechnungs-E-Mail. Wenn Sie die Zahlung selbst storniert haben oder beim Versuch ein Fehler aufgetreten ist, versuchen Sie bitte erneut, sich anzumelden, oder wenden Sie sich an unseren Support.',
            },
            loading: {
                title: 'Warten auf Zahlungsabwicklung',
                description: 'Bitte warten Sie einen Moment',
            },
        }
    },
    editions: {
        title: 'Editions',
    },
    'stat-list': {
        'succesful-alumni': 'Erfolgreicher Abschluss',
        'market-leader': 'SAFe Training Market Leader',
        'certified-trainers': 'Zertifizierte Trainer',
    },
    schedule: {
        title: 'Ablauf',
        results: 'Ergebnisse',
        result: 'Ergebnis',
        'loading-results': 'Ergebnisse werden geladen...',
        'no-results': 'Keine Ergebnisse gefunden,',
        'clear-filters': 'löschen Sie die Filter',
        'to-show-all-options': 'um alle Ergebnisse anzuzeigen.',
        programs: 'Programm',
        filter: 'Filter',
        courses: 'Trainings',
        'start-date': 'Start Datum',
        location: 'Standort',
        language: 'Sprache',
        attendance: 'Trainings-Art',
        'show-next': 'Nächster Schritt',
        'of-total': 'von {count} positionen',
        'ex-vat': 'ohne MwSt',
        'vat-text': '',
        enroll: 'Anmelden'
    },
    trainer: {
        label: 'Zertifizierter Trainer'
    },
    nav: {
        question: 'Fragen?',
        currency: 'Währung',
        'schedule-meeting': 'Jetzt Termin vereinbaren!',
        language: 'Sprache ändern',
        nl: 'Niederländisch',
        en: 'Englisch',
        de: 'Deutsch',
        fr: 'Französisch'
    },
    footer: {
        copyright: '© {year} Gladwell Academy Alle Rechte vorbehalten. Alle genannten Preise verstehen sich exklusive Mehrwertsteuer und Steuern. Sichere Zahlung per',
        courses: 'Trainings',
        'gladwell-academy': 'Gladwell Academy',
        'knowledge-hub': 'Knowledge Hub',
    },
    cookiebar: {
        title: 'Cookies',
        message: 'Wir verwenden Cookies, um Ihre Erfahrung beim Surfen auf unserer Website zu verbessern. Cookies werden zur Personalisierung von Anzeigen verwendet. Weitere Informationen finden Sie in unserer {privacy}',
        link: 'Datenschutz-Bestimmungen',
        saving: 'Speichern...',
        'configure-preferences': 'Einstellungen',
        accept: 'Cookies zustimmen',
        preferences: {
            title: 'Cookie-Einstellungen',
            description: 'Unten können Sie Ihre Cookie-Einstellungen konfigurieren. Technisch erforderliche Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich. Wir verwenden andere Cookies oder verwandte Technologien nur, wenn wir Ihre vorherige Zustimmung haben. Sie haben jederzeit die Möglichkeit, Ihre Einstellungen zu überprüfen und zu ändern. Detaillierte Informationen finden Sie in unserem {link}.',
            link: 'Datenschutz-Bestimmungen',
            subtitle: 'Konfigurieren Sie Ihre Einstellungen',
            confirm: 'Indem Sie dieses Fenster schließen, akzeptieren Sie auch die aktuellen Datenschutzeinstellungen. Klicken Sie auf "OK", um zu akzeptieren, oder auf "Abbrechen", um Änderungen vorzunehmen.',
            save: 'Einstellungen speichern'
        },
        functional: {
            title: 'Funktionale Cookies',
            description: 'die erforderlich sind, damit die Website ordnungsgemäß funktioniert.'
        },
        analytical: {
            title: 'Analytische Cookies',
            description: 'die wir verwenden, um Erkenntnisse darüber zu gewinnen, wie Besucher die Website nutzen.'
        },
        marketing: {
            title: 'Tracking cookies',
            description: 'die wir verwenden, um Einblicke in das Verhalten eines Besuchers über eine oder mehrere Domains zu erhalten.'
        }
    },
    'not-found': {
        title: 'Fehlt noch etwas...',
        message: 'Keine Suchtreffer.'
    },
    error: {
        title: 'Etwas ist schief gelaufen...',
        message: 'Es scheint etwas ist schief gelaufen.'
    },
    'not-available-in-language': {
        title: 'Nicht in dieser Sprache verfügbar',
        description: 'Diese Seite ist nicht in der ausgewählten Sprache verfügbar.',
        instruction: 'Bitte gehen Sie zurück zur',
        home: 'die Homepage',
        or: 'oder',
        'go-back': 'gehen Sie zurück'
    },
    thankyou: {
        title: 'Danke!',
        message: ''
    },
    form: {
        'select-option': 'Wähle eine Option',
    },
    event: {
        'get-tickets': 'Tickets erhalten',
        'get-tickets-now': 'Jetzt Tickets erhalten',
        'read-more': 'Weiterlesen'
    },
    edition: {
        trainers: {
            title: 'Lernen Sie unsere Trainer kennen'
        },
        'training-dates': {
            title: 'Trainings-Termine'
        },
        'more-info': 'Mehr anzeigen',
        'show-more': 'Show more',
        'show-less': 'Show less',
        usp: {
            title: 'Warum die Gladwell Academy?',
            first: 'Größter SAFe-Trainings Anbieter in Europa',
            second: 'Trainer, die sich durch praktische Erfahrung auszeichnen',
            third: 'Scaled Agile Gold Partner'
        },
        'download-brochure': 'Broschüre herunterladen',
        brochure: 'Broschüre',
        'enroll-now': 'Jetzt anmelden',
        conjunction: 'und',
    },
    knowledge: {
        'written-by': 'Geschrieben von',
        search: 'Suche',
        categories: 'Kategorien',
        all: 'Alle',
    }
};
