
import { DnCookiebar } from '@digitalnatives/cookie-consent';
import Cookies from 'universal-cookie';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwCookieModal from '~/patterns/organisms/cookie-modal/cookie-modal.vue';

export default {
    components: {
        DnCookiebar,
        GwButton,
        GwCookieModal
    },

    data() {
        return {
            showModal: false
        };
    },

    computed: {
        isCookiebarHidden() {
            return false;
        },
    },
    methods: {
        openPreferences() {
            this.showModal = true;
        },
        handleClose() {
            this.showModal = false;
        },
        getPrivacyUrl() {
            const localizedUrls = {
                en: '/privacy-statement',
                nl: '/privacy-verklaring',
                fr: '/politique-de-confidentialite',
                de: '/datenschutzerklaerung',
                'en-IN': '/privacy-statement'
            };
            return localizedUrls[this.$i18n.localeProperties.iso];
        },
        setHubspotOptOut(value) {
            const expires = new Date();
            const exdays = 365;

            expires.setTime(expires.getTime() + (exdays * 24 * 60 * 60 * 1000));

            let cookies;
            if (this.$nuxt.req) {
                cookies = new Cookies(this.$nuxt.req.headers.cookie);
            } else {
                cookies = new Cookies();
            }

            cookies.set('__hs_do_not_track', value, { expires, path: '/' });
        }
    }
};
