import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import onError from '~/modules/bugsnag/onError.js';

// Parse lodash template options into usable JS
const options = JSON.parse('{"browserApiKey":"1917569386cd7a6b48b1545cc90b2c28","serverApiKey":"1917569386cd7a6b48b1545cc90b2c28","releaseStage":"production","enabledReleaseStages":["staging","production"]}');

Bugsnag.start({
    apiKey: options.browserApiKey,
    plugins: [new BugsnagPluginVue(Vue)],
    releaseStage: options.releaseStage,
    enabledReleaseStages: options.enabledReleaseStages,
    onError
});
