import { extend, setInteractionMode } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, regex, alpha_dash, numeric } from 'vee-validate/dist/rules.umd.js';

import axios from 'axios';
import config from '~/config/config';

// Set interaction mode
setInteractionMode('betterEager', ({ errors }) => {
    // become slightly aggressive if the field is invalid.
    if (errors.length) {
        return {
            on: ['input'],
            debounce: 150
        };
    }
    // validate immediately after leaving the field.
    return {
        on: ['change', 'custom'],
        debounce: 0
    };
});

extend('regex', {
    ...regex,
    // message: 'The field value is not correct'
});

extend('url', (value) => {
    if (/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i.test(value)) {
        return true;
    }

    return 'That doesn\'t seem to be a valid URL.';
});

extend('email', {
    ...email,
    // message: 'Please enter your e-mail address'
});

extend('phone', (value) => {
    if (/^[0-9()+-. ]*$/i.test(value)) {
        return true;
    }

    return 'That doesn\'t seem to be a valid phone number.';
});

extend('required', {
    ...required,
    // message: 'This field is required'
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    // message: 'Password confirmation does not match'
});

extend('alpha_dash', {
    // eslint-disable-next-line camelcase
    ...alpha_dash,
    // message: 'Only letters, -, and _ characters are allowed.'
});

extend('numeric', numeric);

extend('vat', {
    validate(value) {
        return new Promise((resolve) => {
            const vatNumber = JSON.stringify({ vatNumber: value });

            axios.post(`${config.api.endpoint}/api/vat/check`, vatNumber)
                .then((response) => {
                    resolve(response.data.valid);
                })
                .catch((error) => {
                    console.error(error); // eslint-disable-line no-console
                    resolve(true); // if VIES service is down, always approve vatnumber
                });
        });
    }
});
