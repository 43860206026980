import gladwell from '~/lang/nl.js';

export default {
    ...gladwell,
    footer: {
        ...gladwell.footer,
        copyright: '© {year} Highberg Academy  - Alle rechten voorbehouden. Alle genoemde genoemde bedragen zijn exclusief BTW. Betalingen beveiligd door',
        'gladwell-academy': 'Highberg Academy',
    },
    edition: {
        ...gladwell.edition,
        usp: {
            ...gladwell.edition.usp,
            title: 'Waarom Highberg Academy?',
        }
    },
    'stat-list': {
        'recommended-rate': 'Aanbevolen beoordelingen',
        'organizations-participating': 'Deelnemende organisaties',
        'participants-enrolled': 'Ingeschreven deelnemers',
    },
};
