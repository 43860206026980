export default {
    submit: 'Submit',
    home: {
        courses: 'courses',
        course: 'course',
        'courses-link': 'Courses',
        'all-courses-link': 'All Courses',
        'all-trainers-link': 'All Trainers'
    },
    course: {
        'enroll-today': 'Enroll Today',
        'download-brochure': 'Download Brochure',
        'request-quote': 'Request Quote',
        'participant-score': 'Participant Score',
        'success-rate': 'Success Rate',
        'cta-title': 'Not sure if this course suits you?',
        'cta-body': 'Our training advisor {name} would love to help you further.'
    },
    checkout: {
        title: 'Complete Your Enrollment',
        'remove-participant': 'Remove Participant',
        'add-participant': 'Add Participant',
        'validation-notice': 'Please fill all fields before proceeding to the next step',
        'payment-method-notice': 'Please select a payment method before completing your enrollment',
        required: 'This field is required',
        error: {
            title: 'An error has occurred.',
            body: 'We can\'t process this order right now, please refresh the page and try again. Alternatively, contact us for support and we will help you as soon as possible.'
        },
        labels: {
            'first-name': 'First Name',
            'last-name': 'Last Name',
            email: 'Email',
            phone: 'Telephone Number',
            'customer-type': 'Type of Order',
            'type-corporate': 'Business',
            'type-personal': 'Individual',
            country: 'Country',
            state: 'State',
            zip: 'ZIP/Postal Code',
            city: 'City',
            address: 'Street Adress',
            company: 'Company Name',
            'job-title': 'Job Title',
            vat: 'VAT Number',
            reference: 'Purchase Order Number',
            'pan-number': 'PAN Number',
            'gst-number': 'GST Number',
        },
        placeholders: {
            'first-name': 'John',
            'last-name': 'Doe',
            email: 'john@doe.com',
            phone: '+31 06 12345678',
            country: 'Country',
            zip: '1234 AB',
            city: 'Agile City',
            address: '21 Scrumstreet',
            company: 'Company Inc.',
            'job-title': 'Scrum Master',
            vat: '123456789B01',
            'select-country': 'Select country',
            'select-state': 'Select state',
            reference: '',
            coupon: 'Coupon code',
            'pan-number': 'ALWPG5809L',
            'gst-number': '22AAAA0000A1Z5'
        },
        client: {
            title: 'Participant',
            'button-next': 'Next: Billing Information'
        },
        'special-offer': {
            title: 'Special offer',
            'button-next': 'Next: Special offer',
        },
        billing: {
            title: 'Billing Information',
            'button-next': 'Next: Payment',
            'reuse-information': 'Use contact details of first participant'
        },
        payment: {
            title: 'Payment',
            'button-next': 'Confirm Enrollment',
            'adyen-submit': 'Confirm Payment',
            failed: 'Payment failed',
            'failed-message': 'try again or with a different method',
        },
        'order-summary': {
            'more-info': 'More Info',
            'less-info': 'Less Info',
            trainers: 'Trainers',
            participant: 'participant',
            participants: 'participants',
            vat: 'VAT',
            'calculation-notice': 'Calculated next step',
            total: 'Total',
            'add-coupon': 'Add coupon',
            'cancel-coupon': 'Cancel',
            'apply-coupon': 'Apply coupon',
            'invalid-coupon': 'Coupon code invalid'
        },
        statuses: {
            success: {
                title: 'Great, your order is succesfull',
                description: 'Thank you for ordering. You’ve gifted yourself with time to learn. You’ll receive a confirmation and invoice email within a short moment.',
            },
            error: {
                title: 'Payment failed',
                description: 'Please try to enroll again or contact us for support.',
            },
            pending: {
                title: 'Waiting for payment',
                description: 'We have not received a confirmation of your payment yet. When we do, you’ll receive a confirmation and invoice email within a short moment. If you have cancelled the payment yourself or an error occurred when trying, please try to enroll again or contact us for support.',
            },
            loading: {
                title: 'Waiting for payment',
                description: 'Please wait a moment',
            },
        }
    },
    editions: {
        title: 'Editions',
    },
    'stat-list': {
        'succesful-alumni': 'Succesful Alumni',
        'market-leader': 'SAFe Training Market Leader',
        'certified-trainers': 'Certified Trainers',
    },
    schedule: {
        title: 'Schedule',
        results: 'results',
        result: 'result',
        'loading-results': 'Loading results...',
        'no-results': 'No results found,',
        'clear-filters': 'clear filters',
        'to-show-all-options': 'to show all options',
        programs: 'Programs',
        filter: 'Filter',
        courses: 'Courses',
        'start-date': 'Start Date',
        location: 'Location',
        language: 'Language',
        attendance: 'Learning Style',
        'show-next': 'Show next {count}',
        'of-total': 'of {count} items.',
        'ex-vat': 'excl. VAT',
        'vat-text': '',
        enroll: 'Enroll'
    },
    trainer: {
        label: 'Certified trainer'
    },
    nav: {
        question: 'Question?',
        currency: 'Currency',
        'schedule-meeting': 'Schedule a meeting',
        language: 'Change language',
        nl: 'Dutch',
        en: 'English',
        de: 'German',
        fr: 'French'
    },
    footer: {
        copyright: '© {year} Gladwell Academy All rights reserved. All mentioned prices are exclusive of VAT and taxes. Secure payment by',
        courses: 'Courses',
        'gladwell-academy': 'Gladwell Academy',
        'knowledge-hub': 'Knowledge Hub',
    },
    cookiebar: {
        title: 'Cookies',
        message: 'We use cookies to improve your experience while browsing our website. Cookies are used for ads personalisation. For more information see our {privacy}.',
        link: 'Privacy Policy',
        'configure-preferences': 'Preferences',
        saving: 'Saving...',
        accept: 'I Agree',
        preferences: {
            title: 'Cookie preferences',
            description: 'Below you can configure your cookie preferences. Technically required cookies are essential for the website to function properly. We only use other cookies or related technologies if we have your prior consent. You have the option to check and change your settings at any time. You can find detailed information in our {link}.',
            link: 'Privacy Policy',
            subtitle: 'Configure your preferences',
            confirm: 'By closing this window you\'re also accepting the current privacy settings. Click "OK" to accept, or cancel to make changes.',
            save: 'Save preferences'
        },
        functional: {
            title: 'Functional cookies',
            description: 'that are required that are essential for the website to function properly.'
        },
        analytical: {
            title: 'Analytical cookies',
            description: 'that we use to gain insights into how visitors use the website.'
        },
        marketing: {
            title: 'Tracking cookies',
            description: 'that we use to gain insights into the behaviour of a visitor over one or multiple domains.'
        }
    },
    'not-found': {
        title: 'Something\'s missing...',
        message: 'We can\'t find the page that you\'re looking for.'
    },
    error: {
        title: 'Something went wrong...',
        message: 'It looks like something went wrong there.'
    },
    'not-available-in-language': {
        title: 'Not available in this language',
        description: 'This page is not available in the selected language.',
        instruction: 'Please go back to the',
        home: 'homepage',
        or: 'or',
        'go-back': 'go back'
    },
    thankyou: {
        title: 'Thank you!',
        message: ''
    },
    form: {
        'select-option': 'Select option',
    },
    event: {
        'get-tickets': 'Get tickets',
        'get-tickets-now': 'Get tickets now',
        'read-more': 'Read more'
    },
    edition: {
        trainers: {
            title: 'Meet the trainers'
        },
        'training-dates': {
            title: 'Training dates'
        },
        'more-info': 'More about this course',
        'show-more': 'Show more',
        'show-less': 'Show less',
        usp: {
            title: 'Why Gladwell?',
            first: 'Over 50.000 professionals trained',
            second: 'Transformation journeys in 7+ languages',
            third: 'Scaled Agile Gold Partner'
        },
        'download-brochure': 'Download Brochure',
        brochure: 'Brochure',
        'enroll-now': 'Enroll Now',
        conjunction: 'and'
    },
    knowledge: {
        'written-by': 'Written by',
        search: 'Search',
        categories: 'Categories',
        all: 'All'
    },
};
