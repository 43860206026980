
export default {
    props: {
        backdropType: {
            type: String,
            default: 'quote'
        }
    },
    data() {
        return {
            filename: this.$config.site.group === 'highberg' ? 'graphics-highberg' : 'graphics-gladwell',
        };
    }
};
