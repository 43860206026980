
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        GwButton,
        GwIcon
    },
    props: {
        iconMaximize: {
            type: String,
            default: 'chevron-down'
        },
        iconMinimize: {
            type: String,
            default: 'chevron-up'
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: false
        };
    },
    mounted() {
        this.expanded = this.isOpen;
    },
    methods: {
        toggleExpansion() {
            this.expanded = !this.expanded;
        }
    }
};
