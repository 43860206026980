
import { HOME_URI } from '~/enums/page-uri';

export default {
    props: {
        link: {
            type: [Object, String],
            required: false,
            default: null
        },
        fallbackElement: {
            type: [Object, String],
            required: false,
            default: 'a'
        }
    },
    computed: {
        url() {
            if (!this.link) {
                return null;
            }

            let uri = null;
            if (typeof this.link === 'string') {
                uri = this.link;
            } else if (this.link.element && this.link.element.uri) {
                uri = this.link.element.uri;
            } else if (this.link.url) {
                return this.link.url;
            }

            if (uri === HOME_URI) {
                uri = '/';
            }

            if (!uri) {
                return null;
            }

            uri = uri.replace(/^\/+/g, '');

            return `/${uri}`;
        },

        isExternal() {
            return this.url.startsWith('http');
        }
    }
};
