
import GwExpander from '~/patterns/molecules/expander/expander.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        GwExpander,
        GwIcon,
        GwButton
    },
    data() {
        return {
            items: [],
        };
    },

    async fetch() {
        // Reset any previously set paths
        this.items = [];

        const variables = {
            // Assume the URI here and in craft are the same, stripping the language prefix.
            // uri: this.switchLocalePath(this.$i18n.defaultLocale).replaceAll(/^\/*(.+?)\/*$/g, '$1'),
            uri: this.$route.path.replaceAll(/^\/*(.+?)\/*$/g, '$1')
        };

        if (variables.uri === '/') {
            variables.uri = '__home__';
        }

        // Allow for a custom URI to localize
        // if (this.uri) {
        //     variables.uri = this.uri;
        // }

        // Set the site handle if provided
        variables.site = this.$config.site.siteHandle;

        // Allow for a custom query
        const query = `query localizedUri($uri: [String!], $site: [String!]!) {
            entry(uri: $uri, site: $site) {
                uri
                language
                localized {
                    url
                    language
                    siteHandle
                }
            }
        }`;

        const { data } = await this.$gql.executeQuery(query, variables);

        const otherLangs = this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);

        otherLangs.forEach((language) => {
            // No data means this is likely not a craft entry, so return the locally stored URI
            if (data && !data.entry) {
                this.items.push({
                    language,
                    isFallback: false,
                    url: this.switchLocalePath(language.code),
                    domain: language.domain.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '')
                });
            } else if (data.entry.localized.length) {
                // Attempt to map language codes between nuxt and craft
                // craftLanguage first, isoCode second, fallback to code
                const craftCode = language.craftLanguage || language.iso || language.code;

                // Get the site matching both the language and the site handle
                const localizedEntry = data.entry.localized.filter(l => l.language === craftCode && l.siteHandle === language.craftSiteHandle)[0];

                if (localizedEntry) {
                    // Entry is available in this language
                    this.items.push({
                        language,
                        isFallback: false,
                        url: localizedEntry.url,
                        domain: language.domain.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '')
                    });
                } else {
                    // If entry is not available in this language, point to the home page
                    this.items.push({
                        language,
                        isFallback: true,
                        url: this.localePath('/page-not-available-in-language', language.code),
                        domain: language.domain.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '')
                    });
                }
            }
        });
    },

    watch: {
        '$route.path'() {
            this.$fetch();
        }
    },

    mounted() {
        if (!this.items.length) {
            this.$fetch();
        }
    },

    methods: {
        getFlagEmoji(countryCode) {
            if (!countryCode) {
                return;
            }
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        },
    }
};
