
// @TODO: Rename MyError to [ProjectShortHand]Error
import MyError from '~/patterns/molecules/error.vue';

export default {
    components: {
        MyError
    },

    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
