
import { mapGetters } from 'vuex';

import favicons from '~/mixins/favicons';
import GwHeader from '~/patterns/organisms/header/header.vue';
import GwFooter from '~/patterns/organisms/footer/footer.vue';

export default {
    components: {
        GwHeader,
        GwFooter
    },

    mixins: [favicons],

    head() {
        return {
            meta: [
                ...this.faviconMeta
            ],
            link: [
                ...this.faviconLink
            ]
        };
    },

    computed: {
        ...mapGetters({
            pageType: 'ui/getPageType',
            contact: 'ui/getContact'
        }),

        hasDarkMode() {
            return this.$route?.name?.includes('events-and-webinars-slug') || this.pageType === 'specialPage';
        },

        showContact() {
            return this.pageType === 'specialPage';
        },

        layoutStyle() {
            return this.hasDarkMode ? 'dark' : 'light';
        },

        headerElements() {
            return this.$route?.name?.includes('events-and-webinars-slug') || this.pageType === 'specialPage' ? ['menu-button', 'event-button'] : undefined;
        }
    }
};
